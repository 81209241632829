<template>
  <div class="areaManage">
    <div class="title">区域管理</div>
    <div class="cont">
      <!-- <div class="staff-flex staff-info">
                <div class="staff-flex">
                </div>
            </div> -->
      <div class="nav">
        <ul>
          <li
            v-for="(item, index) in leftNav"
            :key="index"
            @click="navClick(index, item)"
            :class="{ navStype: navIndex == index }"
          >
            <div>
              <div :class="['lv', item.type == 0 ? 'red-bg' : '']"></div>
              {{ item.name }}({{ item.area_room_count }})
            </div>
            <div v-if="item.id > 0">
              <i class="el-icon-edit-outline" @click.stop="editClick(item)" ></i>
              <i class="el-icon-delete" @click.stop="delArea(item)" v-if="navIndex != index"></i>
            </div>
          </li>
        </ul>
        <div class="leftBurron">
          <el-button style="width:100%" type="primary" plain @click="addClick()"
            >新增区域</el-button
          >
        </div>
      </div>
      <div class="rnt">
        <div class="rnt-title">
          <div class="view" v-show="!editShow">
            <div>包厢</div>
            <div>
                <el-button     class="an" type="primary" @click="daoZONGClik">导入包厢</el-button
              >
              <el-popconfirm
              class="an"
                @confirm="dellClick(checkAreaId)"
                title="确定删除吗？"
              >
                <el-button slot="reference" type="primary">删除包厢</el-button>
              </el-popconfirm>
              <el-button
                 class="an"
                type="primary"
                @click="feiClick(1)"
                v-if="checkAreaId == 0"
                >分配区域</el-button
              >
              
              <el-button type="primary" @click="feiClick(2)" v-else
                >取消绑定</el-button
              >
            </div>
          </div>
          <div class="edit" v-show="editShow">
            <div>
              <span>将所选包厢关联到</span>
              <el-radio-group v-model="radio" size="16">
                <el-radio
                  v-for="(item, index) in saiData"
                  :key="index"
                  :label="item.id"
                  >{{ item.name }}
                </el-radio>
              </el-radio-group>
            </div>
            <div>
              <el-button type="info" @click="closeAreaRoom">取消</el-button>
              <el-button type="primary" @click="updateAreaRoom">保存</el-button>
            </div>
          </div>
        </div>
        <ul class="table">
          <li v-for="(item, index) in rntData" :key="index" class="lv" @click="liClick(item)">
            <div>
               <el-checkbox size="medium" v-model="item.is_checked"></el-checkbox>
            </div>
            <div>{{ item.room_name }}</div>
            <div>{{ item.room_mac }}</div>
            <div class="meng-table"></div>
          </li>
        </ul>
      </div>
    </div>
    <!-- 添加adn编辑 -->
    <el-dialog
      :title="tanPang.title"
      :visible.sync="tanPang.dialogVisible"
      width="680px"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="区域名称" prop="name">
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="tanPang.dialogVisible = false"
          style="   background: rgba(62, 86, 110, 0.1);"
          >取 消</el-button
        >
        <el-button type="primary" @click="queAddClick()">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
        title="导入包厢"
        :visible.sync="daoRu"
        width="530px">
        <div class="daoStyle">
          <div class="li">
            <div>下载模板</div>
            <div>
                <el-button style="width:60%" @click="xiazaiClick">下载模板</el-button>
            </div>
          </div>
          <div class="li">
             <div>上传文件</div>
             <div style="display: flex;align-items: center;">
                <el-upload
                  accept=".xls"
                  style='margin-right:10px;width:300px'
                    class="avatar-uploader"
                    action="#"
                    :http-request="httpRequest"
                    :show-file-list="false">
                      <el-button  style="width:100%">上传文件</el-button>
                </el-upload>
                <span>{{ fileName }}</span>
             </div>
          </div>
          <div class="li">
            <div></div>
             <div style="color:#ccc">
                 导入文件时，如mac地址已存在，则会覆盖已有数据
             </div>
          </div>
        </div>
        <span slot="footer" style="text-align: left;">
          <div style='margin-left:130px;'>
            <el-button @click="daoRu = false">取 消</el-button>
            <el-button type="primary" :disabled="fileName != '' ? false : true"  @click="daoRuClik()">开始导入</el-button>
          </div>
        </span>
      </el-dialog>

      <el-dialog
        :visible.sync="chengShow"
        width="530px">
        <div class="jieGuo">
            <div class="jieGuo-title">
              <div>导入结果</div>
              <div>导入成功{{ chengData.success_count }}</div>
              <div>导入失败{{ chengData.error_count }}</div>
            </div>
             <el-table
                :data="tanData"
                max-height="500"
                border
                style="width: 100%">
                <el-table-column
                  prop="room_mac"
                  label="点歌机mac地址">
                </el-table-column>
                <el-table-column
                  prop="room_name"
                  label="包厢名称">
                </el-table-column>
                <el-table-column
                  prop="msg"
                  label="失败原因">
                </el-table-column>
              </el-table>
        </div>
        <span slot="footer">
           <el-button type="primary" @click="chengShow = false">确定</el-button>
        </span>
      </el-dialog>

  </div>
</template>

<script>
import {
  addArea,
  areaList,
  getBingUserAreaList,
  getRoomByArea,
  saveDelArea,
  updateArea,
  updateBindArea,
  delAreaRoom,
} from "../../../api/srv-store";

export default {
  data() {
    return {
      checkBrand: "",
      checkCompany: "",
      checkAreaId: "",
      brandList: [],
      companyList: [],
      leftNav: [],
      navIndex: 0,
      editShow: false,
      radio: "",
      saiData: [],
      rntData: [],
      tanPang: {
        title: "新增区域",
        dialogVisible: false,
      },
      ruleForm: {
        id: "",
        name: "",
      },
      rules: {
        name: [
          { required: true, message: "请输入区域名称", trigger: "blur" },
          { min: 1, max: 5, message: "长度在 1 到 5 个字符", trigger: "blur" },
        ],
      },
      bindRoomType: 1, //1#分配；2#取消绑定
      daoRu:false,
      daodisabled:true,
      fileName:'',
      file:null,
      chengShow:false,
      chengData:[],
      tanData:[],
      erNum:0
    };
  },

  created() {
    this.checkBrand = JSON.parse(localStorage.getItem("brandId"));
    this.checkCompany = JSON.parse(localStorage.getItem("storesId"));
    this.getAreaList();
  },

  methods: {
    //获取区域列表
    getAreaList() {
      areaList({
        brand_id: this.checkBrand,
        store_id: this.checkCompany,
      }).then((Er) => {
        if (Er.return_code === 200) {
          this.leftNav = Er.data;
          this.checkAreaId =
            this.checkAreaId !== "" ? this.checkAreaId : this.leftNav[0].id;
          this.roomByArea();
        }
      });
    },

    //选中分配类型
    navClick(index, item) {
      this.editShow = false;
      this.navIndex = index;
      this.checkAreaId = item.id;
      this.roomByArea();
    },

    //获取包厢列表
    roomByArea() {
      getRoomByArea({
        brand_id: this.checkBrand,
        store_id: this.checkCompany,
        area_id: this.checkAreaId,
      }).then((Er) => {
        if (Er.return_code === 200) {
          Er.data.map(EC =>{
            EC.is_checked = JSON.parse(EC.is_checked)
          })
          this.rntData = Er.data;
        }
      });
    },

    // 分配区域
    feiClick(val) {
      if (this.checkAreaId === 0) {
        //获取绑定分配区域列表
        getBingUserAreaList({
          brand_id: this.checkBrand,
          store_id: this.checkCompany,
        }).then((Er) => {
          if (Er.return_code === 200) {
            this.saiData = Er.data;
          }
        });
      } else {
        this.saiData = [];
      }
      this.bindRoomType = val;
      this.editShow = true;
    },

    //新增区域
    addClick() {
      if (this.$refs["ruleForm"] !== undefined) {
        this.$refs["ruleForm"].resetFields();
      }
      this.ruleForm.name = "";
      this.tanPang.dialogVisible = true;
    },

    //编辑
    editClick(row) {
      this.ruleForm.name = "";
      this.tanPang = {
        title: "编辑区域",
        dialogVisible: true,
      };
      this.ruleForm.name = row.name;
      this.ruleForm.id = row.id;
    },

    //删除区域
    delArea(row) {
      saveDelArea({
        area_id: row.id,
      }).then((Er) => {
        if (Er.return_code === 200) {
          this.$message({
            type: "success",
            message: Er.return_msg,
          });
          this.getAreaList();
        }
      });
    },

    liClick(row){
      row.is_checked = !row.is_checked
    },

    // 确认新增
    queAddClick() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          if (this.ruleForm.id !== "") {
            //编辑
            updateArea({
              area_id: this.ruleForm.id,
              name: this.ruleForm.name,
            }).then((Er) => {
              if (Er.return_code === 200) {
                this.$message({
                  type: "success",
                  message: Er.return_msg,
                });
                this.ruleForm.id = "";
                this.tanPang.dialogVisible = false;
                this.getAreaList();
              }
            });
          } else {
            //新增
            addArea({
              brand_id: this.checkBrand,
              store_id: this.checkCompany,
              name: this.ruleForm.name,
            }).then((Er) => {
              if (Er.return_code === 200) {
                this.$message({
                  type: "success",
                  message: Er.return_msg,
                });
                this.tanPang.dialogVisible = false;
                this.getAreaList();
              }
            });
          }
        }
      });
    },

    //取消保存包厢关联区域信息
    closeAreaRoom() {
      this.editShow = false;
      this.radio = "";
    },

    //保存包厢关联区域信息
    updateAreaRoom() {
      let room_id = [];
      if (this.bindRoomType === 2) {
        //取消
        this.rntData.map((item) => {
          if (item.is_checked === true) {
            room_id.push(item.id);
          }
        });
        if (room_id.length <= 0) {
          this.$message({
            type: "warning",
            message: "请选择要取消绑定包厢",
          });
          return false;
        }
      } else {
        //分配
        this.rntData.map((item) => {
          if (item.is_checked === true) {
            room_id.push(item.id);
          }
        });
        if (room_id.length <= 0) {
          this.$message({
            type: "warning",
            message: "请选择要分配的包厢",
          });
          return false;
        }
        if (this.radio === "") {
          this.$message({
            type: "warning",
            message: "请选择包厢关联的区域",
          });
          return false;
        }
      }
      updateBindArea({
        area_id: this.radio,
        room_ids: room_id.join(","),
      }).then((Er) => {
        if (Er.return_code === 200) {
          this.$message({
            type: "success",
            message: Er.return_msg,
          });
          this.editShow = false;
          this.radio = "";
          this.getAreaList();
        }
      });
    },
    //删除包厢
    dellClick(row){
       let room_id = [];
        this.rntData.map((item) => {
          if (item.is_checked === true) {
            room_id.push(item.id);
          }
        });
        if(room_id.length > 0){
          delAreaRoom({
        brand_id: this.checkBrand,
        store_id: this.checkCompany,
        area_id:  row != 0 ? this.checkAreaId : '',
         room_ids: room_id.join(","),
      }).then(er =>{
        if(er.return_code == 200){
          this.roomByArea()
           this.$message({
            type: "success",
            message: '删除成功',
          });
        }
      })
        }else{
           this.$message({
            type: "warning",
            message: "请选择要删除的包厢",
          });
        }
      
    },
    //下载文件
    xiazaiClick(){
       let a = document.createElement("a");
        a.href = 'https://zzyy.resource.i-ktv.com/yinchuang/bx_import.xls';
        a.setAttribute("download", "导入");
        a.click();
    },
    //导入包厢
    daoZONGClik(){
      this.daoRu = true;
        this.fileName = ''
    },
    //上传文件
    httpRequest(file){
      this.fileName = file.file.name
      this.file = file.file
    },
    //确认导入
    daoRuClik(){
      let _this = this;
      const form = new FormData()
      // 文件对象
      form.append('file', this.file) //文件流参数
      form.append('brand_id', this.checkBrand)
      form.append('store_id', this.checkCompany)
      console.log(form)
      this.flexUp(form).then(er =>{
        if(er.data.return_code == 200){
           _this.roomByArea()
          _this.daoRu = false
          _this.chengShow = true
          _this.chengData = er.data.data
          _this.tanData = er.data.data.error.concat(er.data.data.success)
        }
      })
    }
  },
};
</script>
<style>
.el-upload{
  width: 198px
}
</style>


<style lang="scss" scoped>
.areaManage {
  .title {
    font-size: 30px;
    font-weight: 600;
    color: #3e566e;
    padding: 40px 0px 30px 0px;
  }

  .cont {
    height: calc(100vh - 200px);
    background: #ffffff;
    box-shadow: 0px 10px 20px 0px rgba(62, 86, 110, 0.06);
    border-radius: 6px;
    display: flex;

    .nav {
      width: 360px;
      height: 100%;
      border-right: 1px solid rgba(0, 0, 0, 0.05);
      padding: 21px 0px;
      position: relative;

      > ul {
        > li {
          height: 60px;
          display: flex;
          align-items: center;

          > div {
            &:nth-child(1) {
              width: 70%;
              padding-left: 40px;
              font-size: 16px;
              font-weight: 400;
              color: #3e566e;
              display: flex;
              align-items: center;

              > div {
                width: 12px;
                height: 12px;
                border-radius: 50%;
                margin-top: 3px;
                margin-right: 5px;
              }

              > .huang {
                background: #ffcf00;
              }

              > .lan {
                background: #0097ff;
              }

              > .hong {
                background: #ff6e99;
              }

              > .lv {
                background: #00bc67;
              }
            }

            &:nth-child(2) {
              width: 30%;
              text-align: right;
              padding-right: 20px;

              > i {
                font-size: 15px;
                color: #1989fa;

                &:nth-child(2) {
                  margin-left: 25px;
                }

                &:hover {
                  cursor: pointer;
                }
              }
            }
          }

          &:hover {
            background: #e0f3ff;
          }
        }

        > .navStype {
          background: #e0f3ff;
        }

        .red-bg {
          background-color: #ff0000 !important;
        }
      }

      .leftBurron {
        width: 100%;
        position: absolute;
        bottom: 117px;
        padding: 0px 75px;
      }
    }

    .rnt {
      width: calc(100% - 360px);
      padding: 0px 40px;

      .rnt-title {
        padding: 40px 0px 30px 0px;

        .view {
          display: flex;
          align-items: center;
          width: 100%;

          > div {
            width: 30%;
            font-size: 20px;
            font-weight: 400;
            color: #3e566e;
            &:nth-child(2) {
              text-align: right;
               width: 70%;
            
            }
          }
        }

        .edit {
          display: flex;

          > div {
            &:nth-child(1) {
              width: 75%;
              display: flex;
              align-items: center;

              > span {
                font-size: 16px;
                font-weight: 400;
                color: #dc2718;
                margin-right: 30px;
              }
            }

            &:nth-child(2) {
              text-align: right;
              width: 25%;
              > button {
                width: calc(50% - 10px);
              }
            }
          }
        }
      }

      .table {
        max-height: calc(100% - 110px);
        display: flex;
        flex-wrap: wrap;
        margin: 0px -6px;
        overflow-y: auto;
        > li {
          margin: 6px;
          width: 180px;
          background: #e0f3ff;
          border-radius: 10px;
          position: relative;
          > div {
            &:nth-child(1){
              height: 40px;
              display: flex;
              justify-content: flex-end;
              padding: 10px 10px;
            }
            &:nth-child(2){
              height: 100px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 28px;
              font-family: Arial-BoldMT, Arial;
              font-weight: normal;
              color: #0097ff;
              flex-wrap: wrap;
            }
             &:nth-child(3){
              height: 40px;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-right: 10px;
                color: #0097ff;
            
            }
            // color: #0097ff;
            // font-size: 36px;
            // font-family: Arial-BoldMT, Arial;
            // font-weight: normal;
            // height: 150px;
            // display: flex;
            // align-items: center;
            // justify-content: center;
          }
          .meng-table{
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0px;
            left: 0px;
            z-index: 999
          }
          
        }

        > .lan {
          background: #e0f3ff;
        }

        > .fen {
          background: #ffeff3;
        }

        > .lv {
          background: #d6f3e3;
        }
      }
    }
  }
}

/deep/ .el-radio__label {
  font-size: 16px;
}

/deep/ .el-checkbox__inner {
  width: 25px;
  height: 25px;
}

/deep/ .el-checkbox__inner::after {
  height: 10px;
  left: 9px;
  position: absolute;
  top: 4px;
  transform: rotate(45deg) scaleY(0);
  width: 3px;
  transition: transform 0.15s ease-in 0.05s;
  transform-origin: center;
}

/deep/ .el-dialog__footer {
  text-align: center;
}
.an{
  margin-right:10px 
}
.daoStyle{
    padding: 0px 30px;
  >.li{
    display: flex;
    align-items: center;
    margin-top: 20px;

    &:nth-child(1){
      margin-top: 0px
    }
    >div{
      &:nth-child(1){
        width: 100px;
      }
      &:nth-child(2){
        width: calc(100% - 100px);
      }
    }
  }
}
.jieGuo{
  .jieGuo-title{
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    >div{
      width: 33.333%
    }
  }
}

</style>
